import https from "./https"
import store from "@/store"

const api = {
  getThemeGroupbuyings (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/theme-groupbuyings`, { params })
  },
  getThemeGroupbuying (id) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/theme-groupbuyings/${id}`)
  },
  storeThemeGroupbuyings (params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/theme-groupbuyings`, params)
  },
  updateThemeGroupbuying (params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/theme-groupbuyings/${params.id}`, params)
  },
  removeThemeGroupbuying (themeGroupbuying) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/theme-groupbuyings/' + themeGroupbuying.id;
    return https.delete(url)
  },
}

export default api
