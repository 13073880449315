<template>
  <b-card class="shadow-none mb-3" header-bg-variant="light" header-class="p-3">
    <div class="message-flex">
      <div class="d-flex">
        <div class="image mr-4">
          <b-card
            class="m-3 flex-button rounded-lg"
            no-body
            style="backgroundcolor: #fffff9"
          >
            <span v-if="form.tag_title" class="custom-badge altText">
              {{ form.tag_title }}
            </span>

            <template v-if="!onlyImage">
              <div class="image-frame">
                <b-img center :src="imgSrc" />
              </div>
            </template>
            <template v-else>
              <div
                v-for="(a, adx) in form.footer_contents"
                :key="adx"
                class="image-frame"
                :style="{
                  aspectRatio: `${a.area.width}/${a.area.height}`,
                }"
              >
                <a
                  :href="a.uri ? a.uri : '#'"
                  :target="a.uri ? '_blank' : '_self'"
                  :title="a.label"
                >
                  <b-img
                    center
                    :src="a.img_url"
                    v-if="a.img_url"
                    :alt="a.label"
                  />
                </a>
              </div>
            </template>

            <div v-if="!onlyImage" class="p-3 body">
              <h4
                class="lg font-weight-bold text-center mb-0"
                style="color: #de006f"
              >
                {{ form.title || "頁面標題" }}
              </h4>
              <!-- <h4
                class="sm"
                style="fontweight: normal; textalign: left; color: #000000"
              >
                {{ form.description }}
              </h4> -->
            </div>

            <div class="footer p-0">
              <div
                v-for="(f, fdx) in form.footer_contents.filter(
                  (x) => x.type == 'button'
                )"
                :key="fdx"
              >
                <b-button
                  :href="f.uri"
                  variant="link"
                  target="_blank"
                  block
                  class="m-0 p-2"
                  v-if="f.label != ''"
                >
                  {{ f.label }}
                </b-button>
              </div>
            </div>
          </b-card>
        </div>

        <div class="flex-fill">
          <div class="row">
            <validation-error-alert
              v-if="validationErrors"
              :errors="validationErrors"
            ></validation-error-alert>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label text-right">*頁面標題</label>
            <div class="col-sm-10">
              <b-input
                type="text"
                class="form-control"
                placeholder="輸入標題"
                maxlength="20"
                :state="v$.form.title.$error ? false : null"
                required
                v-model="form.title"
                :readonly="isReadonly"
              />
              <b-form-invalid-feedback :state="!v$.form.title.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label text-right">*起始時間</label>
            <div class="col-sm-10">
              <div class="row">
                <div class="col-12 col-xl-6">
                  <datepicker
                    placeholder="Select Date"
                    v-model="startAt.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabledDates="disabledDates"
                    :disabled="isReadonly || doesInProgress()"
                  ></datepicker>
                  <b-form-invalid-feedback
                    :state="!v$.startAt.date.required.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>

                <div class="col-12 col-xl-6">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="startAt.time"
                    :input-class="['form-control']"
                    :disabled="isReadonly || doesInProgress()"
                  ></vue-timepicker>
                  <b-form-invalid-feedback
                    :state="
                      !v$.startAt.time.HH.$error || !v$.startAt.time.mm.$error
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label text-right">*結束時間</label>
            <div class="col-sm-10">
              <div class="row">
                <div class="col-12 col-xl-6">
                  <datepicker
                    placeholder="Select Date"
                    v-model="endAt.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :input-class="{
                      'is-invalid': v$.startAt.$invalid,
                    }"
                    :disabledDates="disabledDates"
                    :disabled="isReadonly"
                  ></datepicker>
                  <b-form-invalid-feedback :state="!v$.endAt.date.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    :state="!v$.startAt.date.beforeEndAt.$invalid"
                  >
                    結束時間必須大於起始時間
                  </b-form-invalid-feedback>
                </div>

                <div class="col-12 col-xl-6">
                  <vue-timepicker
                    :input-class="[
                      'form-control',
                      {
                        'is-invalid':
                          v$.startAt.time.HH.$error ||
                          v$.startAt.time.mm.$error ||
                          v$.startAt.date.beforeEndAt.$invalid,
                      },
                    ]"
                    v-model="endAt.time"
                    :disabled="isReadonly"
                  ></vue-timepicker>
                  <b-form-invalid-feedback
                    :state="
                      !v$.endAt.time.HH.$error || !v$.endAt.time.mm.$error
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label text-right">*順序</label>
            <div class="col-sm-10">
              <b-input
                type="number"
                class="form-control"
                placeholder="輸入順序"
                min="1"
                maxlength="20"
                :state="v$.form.order.$error ? false : null"
                required
                v-model="form.order"
                :readonly="isReadonly"
              />
              <b-form-invalid-feedback :state="!v$.form.order.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>

          <hr />

          <div class="row justify-content-end">
            <div class="text-muted small col-10 mb-3">
              請注意：不同高度的圖卡於同時間啟用會導致跑版
            </div>
          </div>

          <!--Template Start-->
          <div class="form-group row justify-content-end">
            <div v-for="ht in [2048, 1588]" :key="ht" class="col-sm-5">
              <div class="card shadow-none rounded-3 h-100">
                <div class="card-header p-2 text-center bg-light">
                  高：{{ ht }}
                </div>
                <div
                  class="p-2 d-flex justify-content-around align-items-stretch"
                >
                  <label
                    v-for="i in typeTemplate.filter(
                      (x) => x.baseSize.height == ht
                    )"
                    :key="i.type"
                    class="template d-flex flex-column"
                    :for="`templateRadio${i.type}`"
                    role="button"
                  >
                    <div class="text-center mb-2">
                      <input
                        type="radio"
                        :id="`templateRadio${i.type}`"
                        :value="i.type"
                        :checked="form.type == i.type"
                        @change="switchType($event, i)"
                        :disabled="isReadonly"
                      />
                    </div>

                    <div class="border d-flex flex-column flex-fill">
                      <div
                        v-for="(j, jdx) in i.type == 40 || i.type == 80
                          ? i.actions.slice(0, 1)
                          : i.actions"
                        :key="jdx"
                        class="
                          bg-secondary
                          img
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                        :style="{
                          minHeight: `${
                            i.type == 40 || i.type == 80
                              ? (768 / i.baseSize.height) * 100
                              : (j.area.height / i.baseSize.height) * 100
                          }%`,
                          aspectRatio:
                            i.type == 40 || i.type == 80
                              ? '4/3'
                              : `${j.area.width}/${j.area.height}`,
                        }"
                      >
                        <span class="font-weight-bold text-white">{{
                          ["A", "B", "C", "D"][jdx]
                        }}</span>
                      </div>
                      <div
                        v-if="i.type == 40 || i.type == 80"
                        class="
                          flex-fill
                          p-1
                          d-flex
                          flex-column
                          justify-content-around
                        "
                      >
                        <div
                          v-for="b in i.type / 10"
                          :key="b"
                          class="border"
                        ></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!--Template End-->

          <div
            v-if="currentTemplate.type == 40 || currentTemplate.type == 80"
            class="form-group row"
          >
            <label class="col-sm-2 col-form-label text-right">*圖片</label>
            <div class="col-sm-10">
              <div class="s-image-list-item s-draggable" v-if="imgSrc">
                <img class="s-cropped-image" :src="imgSrc" />
                <button
                  v-if="!isReadonly"
                  class="btn btn-danger s-delete-btn"
                  type="button"
                  @click="deleteImage()"
                >
                  <i class="fa fa-times m-0" />
                </button>
              </div>
              <div class="d-flex" v-else>
                <div class="s-image-list-item mr-3">
                  <ImageSelector @input="selectImage" :aspect-ratio="4 / 3">
                    <template #trigger="{ openFileSelector }">
                      <button
                        class="btn s-trigger-btn"
                        @click="openFileSelector"
                      >
                        <i class="fa fa-plus"></i> 圖片
                      </button>
                    </template>
                  </ImageSelector>
                </div>

                <ul>
                  <li
                    v-for="(d, ddx) in currentTemplate.descriptions"
                    :key="ddx"
                  >
                    {{ d }}
                  </li>
                </ul>
              </div>

              <b-form-invalid-feedback :state="!v$.imgSrc.$error">
                請上傳圖片
              </b-form-invalid-feedback>
            </div>
          </div>

          <div v-for="(f, fdx) in form.footer_contents" :key="fdx">
            <div class="form-group row">
              <label
                class="col-sm-2 col-form-label text-right font-weight-bold"
              >
                {{
                  f.type == "uri" ? ["A", "B", "C", "D"][fdx] : `動作${fdx + 1}`
                }}
              </label>
              <div class="col-sm-10">
                <div class="mb-3" v-if="f.type == 'uri'">
                  <div class="d-flex">
                    <div
                      class="s-image-list-item s-draggable mr-4"
                      v-if="f.img_url"
                    >
                      <img class="s-cropped-image" :src="f.img_url" />
                      <button
                        v-if="!isReadonly"
                        class="btn btn-danger s-delete-btn"
                        type="button"
                        @click="
                          (x) => {
                            f.img_url = null;
                          }
                        "
                      >
                        <i class="fa fa-times m-0"></i>
                      </button>
                    </div>
                    <div v-else class="s-image-list-item mr-4">
                      <ImageSelector
                        @input="selectImage($event, f)"
                        :aspect-ratio="f.area.width / f.area.height"
                      >
                        <template #trigger="{ openFileSelector }">
                          <button
                            class="btn s-trigger-btn"
                            @click="openFileSelector"
                          >
                            <i class="fa fa-plus"></i> 圖片
                          </button>
                        </template>
                      </ImageSelector>
                    </div>
                    <ul class="mb-0">
                      <li
                        v-for="(d, ddx) in currentTemplate.descriptions"
                        :key="ddx"
                      >
                        {{ d }}
                      </li>
                    </ul>
                  </div>
                  <b-form-invalid-feedback
                    :state="
                      v$.form.footer_contents.$dirty &&
                      v$.form.footer_contents.$each.$response.$data[fdx].img_url
                        .$error
                        ? false
                        : null
                    "
                  >
                    請上傳圖片
                  </b-form-invalid-feedback>
                </div>

                <div class="mb-3">
                  <b-input
                    class="form-control"
                    placeholder="輸入動作標籤的說明"
                    maxlength="15"
                    required
                    :state="
                      v$.form.footer_contents.$dirty &&
                      v$.form.footer_contents.$each.$response.$data[fdx].label
                        .$error
                        ? false
                        : null
                    "
                    v-model.trim="f.label"
                    :readonly="isReadonly"
                  />
                  <b-form-invalid-feedback
                    :state="
                      v$.form.footer_contents.$dirty &&
                      v$.form.footer_contents.$each.$response.$data[fdx].label
                        .$error
                        ? false
                        : null
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>

                <div class="mb-3">
                  <b-input
                    class="form-control"
                    type="url"
                    placeholder="輸入網址"
                    required
                    v-model.trim="f.uri"
                    :readonly="isReadonly"
                    :state="
                      v$.form.footer_contents.$dirty &&
                      v$.form.footer_contents.$each.$response.$data[fdx].uri
                        .$error
                        ? false
                        : null
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      v$.form.footer_contents.$dirty &&
                      v$.form.footer_contents.$each.$response.$data[fdx].uri
                        .$error
                        ? false
                        : null
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
              <!-- <div class="col-sm-2 text-right">
                <b-button
                  variant="outline-dark"
                  size="sm"
                  class="px-2"
                  @click="removeAction(fdx)"
                  :disabled="isReadonly"
                >
                  <i class="fa fa-times m-0" aria-hidden="true"></i>
                </b-button>
              </div> -->
            </div>
          </div>

          <!-- <div class="form-group row">
            <div class="offset-sm-2 col-sm-10">
              <b-button
                variant="primary"
                @click="addAction"
                :disabled="isReadonly"
                >新增動作</b-button
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import { format, isBefore, set, subDays } from "date-fns";
import ImageSelector from "@/components/ImageSelector";
import _ from "lodash";

const typeTemplate = [
  {
    type: 80,
    baseSize: {
      width: 1024,
      height: 2048,
    },
    descriptions: [
      "尺寸：寬 1024px、高 768px",
      "比例：4:3",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "button",
        order:0,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:1,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:2,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:3,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:4,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:5,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:6,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:7,
        uri: "",
        img_url: "",
        label: "",
      },
    ],
  },
  {
    type: 81,
    baseSize: {
      width: 1024,
      height: 2048,
    },
    descriptions: [
      "尺寸：寬 1024px、高 2048px",
      "比例：1:2",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 2048,
        },
      },
    ],
  },
  {
    type: 82,
    baseSize: {
      width: 1024,
      height: 2048,
    },
    descriptions: [
      "尺寸：寬 1024px、高 1024px",
      "比例：1:1",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 1024,
        },
      },
      {
        type: "uri",
        order:1,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 1024,
          width: 1024,
          height: 1024,
        },
      },
    ],
  },
  {
    type: 83,
    baseSize: {
      width: 1024,
      height: 2048,
    },
    descriptions: [
      "尺寸：寬 1024px、高 683px",
      "比例：3:2",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 683,
        },
      },
      {
        type: "uri",
        order:1,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 683,
          width: 1024,
          height: 683,
        },
      },
      {
        type: "uri",
        order:2,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 1366,
          width: 1024,
          height: 683,
        },
      },
    ],
  },
  {
    type: 84,
    baseSize: {
      width: 1024,
      height: 2048,
    },
    descriptions: [
      "尺寸：寬 1024px、高 512px",
      "比例：2:1",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 512,
        },
      },
      {
        type: "uri",
        order:1,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 512,
          width: 1024,
          height: 512,
        },
      },
      {
        type: "uri",
        order:2,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 1024,
          width: 1024,
          height: 512,
        },
      },
      {
        type: "uri",
        order:3,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 1536,
          width: 1024,
          height: 512,
        },
      },
    ],
  },
  {
    type: 40,
    baseSize: {
      width: 1024,
      height: 1588,
    },
    descriptions: [
      "尺寸：寬 1024px、高 768px",
      "比例：4:3",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "button",
        order:0,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:1,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:2,
        uri: "",
        img_url: "",
        label: "",
      },
      {
        type: "button",
        order:3,
        uri: "",
        img_url: "",
        label: "",
      },
    ],
  },
  {
    type: 41,
    baseSize: {
      width: 1024,
      height: 1588,
    },
    descriptions: [
      "尺寸：寬 1024px、高 1536px",
      "比例：2:3",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 1536,
        },
      },
    ],
  },
  {
    type: 42,
    baseSize: {
      width: 1024,
      height: 1588,
    },
    descriptions: [
      "尺寸：寬 1024px、高 768px",
      "比例：4:3",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 768,
        },
      },
      {
        type: "uri",
        order:1,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 768,
          width: 1024,
          height: 768,
        },
      },
    ],
  },
  {
    type: 43,
    baseSize: {
      width: 1024,
      height: 1588,
    },
    descriptions: [
      "尺寸：寬 1024px、高 512px",
      "比例：2:1",
      "檔案大小：1 MB 以下",
    ],
    actions: [
      {
        type: "uri",
        order:0,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 0,
          width: 1024,
          height: 512,
        },
      },
      {
        type: "uri",
        order:1,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 512,
          width: 1024,
          height: 512,
        },
      },
      {
        type: "uri",
        order:2,
        img_url: "",
        uri: "",
        label: "",
        area: {
          x: 0,
          y: 1024,
          width: 1024,
          height: 512,
        },
      },
    ],
  },
];

export default {
  components: { Datepicker, VueTimepicker, ImageSelector },
  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      form: {
        title: { required },
        order: { required },
        footer_contents: {
          $each: helpers.forEach({
            label: {
              required: requiredIf(() => {
                return !this.onlyImage;
              }),
            },
            uri: {
              required: requiredIf(() => {
                return !this.onlyImage;
              }),
            },
            img_url: {
              required: requiredIf(() => {
                return this.onlyImage;
              }),
            },
          }),
        },
      },
      startAt: {
        date: {
          required,
          beforeEndAt: () => {
            const startDate = set(new Date(this.startAt.date), {
              hours: this.startAt.time.HH,
              minutes: this.startAt.time.mm,
            });

            const endDate = set(new Date(this.endAt.date), {
              hours: this.endAt.time.HH,
              minutes: this.endAt.time.mm,
            });

            return isBefore(startDate, endDate);
          },
        },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      endAt: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      imgSrc: {
        required: requiredIf(() => {
          return !this.onlyImage;
        }),
      },
    };
  },

  props: {
    imgFile: {
      required: false,
    },
    startAt: {
      required: true,
    },
    endAt: {
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    validationErrors: {
      required: false,
    },
    formMode: {
      type: String,
      default: "view",
    },
  },

  data() {
    return {
      zh,
      imgSrc: null,
      disabledDates: { to: subDays(new Date(), 1) },
      typeTemplate,
    };
  },

  computed: {
    isReadonly() {
      return this.formMode == "view";
    },
    onlyImage() {
      return this.currentTemplate.type != 40 && this.currentTemplate.type != 80;
    },
    currentTemplate() {
      return this.typeTemplate.find((x) => x.type == this.form.type);
    },
  },

  watch: {
    startAt: {
      handler(value) {
        this.form.start_at = format(
          set(new Date(value.date), {
            hours: Number(value.time.HH),
            minutes: Number(value.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        );
      },
      deep: true,
    },
    endAt: {
      handler(value) {
        this.form.end_at = format(
          set(new Date(value.date), {
            hours: value.time.HH,
            minutes: value.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        );
      },
      deep: true,
    },
    "form.order": function () {
      this.form.order = this.form.order.replace(/^0+(?=\d)/, "");

      // 確保輸入的值為數字，如果不是，則設置為空字串
      const parsedValue = parseFloat(this.form.order);
      if (isNaN(parsedValue)) {
        this.form.order = "";
      }
    }
  },

  mounted() {
    if (this.form.pic_url) {
      this.imgSrc = this.form.pic_url;
    }

    if (this.formMode == "create") {
      this.form.footer_contents = _.cloneDeep(this.typeTemplate[0].actions);
    }
  },

  methods: {
    doesInProgress() {
      const startDate = set(new Date(this.startAt.date), {
        hours: this.startAt.time.HH,
        minutes: this.startAt.time.mm,
      });
      return this.formMode != "create" && isBefore(startDate, new Date());
    },
    switchType(e, template) {
      this.$swal({
        title: "您確定切換版型嗎？",
        text: "您的資料將不會儲存，是否確認切換？",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.value) {
          this.form.type = template.type;
          this.form.footer_contents = _.cloneDeep(template.actions);
        } else {
          e.target.checked = false;
        }
      });

      // if (confirm("您確定切換版型嗎？您的資料將不會儲存，是否確認切換？")) {
      //   this.form.type = template.type;
      //   this.form.footer_contents = _.cloneDeep(template.actions);
      // } else {
      //   e.target.checked = false;
      // }
    },
    selectImage(image, action) {
      if (this.onlyImage) {
        action.img_url = image;
      } else {
        this.imgSrc = image;
        this.$emit("update:imgFile", image);
      }
    },
    deleteImage() {
      this.imgSrc = null;
      this.$emit("update:imgFile", null);
    },
    removeAction(index) {
      this.form.footer_contents.splice(index, 1);
    },
    addAction() {
      this.form.footer_contents.push({ label: "", uri: "" });
    },
    async validate() {
      return await this.v$.$validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.message-flex {
  .image {
    width: 320px;

    .image-frame {
      position: relative;
      background-color: #ddd;
      aspect-ratio: 4/3;

      img {
        width: 100%;
        height: auto;
        border: none;
      }
    }
  }

  .flex-button {
    overflow: hidden;
    border-radius: 1rem !important;

    .altText {
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 1;
    }

    .body {
      .lg {
        font-size: 1.375rem;
      }
      .md {
        font-size: 1rem;
      }
      .sm {
        font-size: 0.875rem;
      }
    }

    .footer {
      background-color: transparent;
      border: none;
      .btn {
        font-size: 1rem;
      }
    }
  }
}

.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: 6rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: auto;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;

  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.custom-badge {
  border-radius: 0.25rem;
  font-size: 11px;
  font-weight: initial;
  line-height: 1;
  padding: 4px 6px;
  font-family: "roboto", sans-serif;
  font-weight: 600;
  letter-spacing: 0.04rem;
}

.template {
  width: 20%;
  margin: 0 5px;

  > .border {
    padding: 2px;
  }

  .img {
    border: solid 2px white;
  }
}
</style>
