<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'ThemeGroupbuyingList' }"
              >主題團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>新增主題團購</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-xl-2">
          <h4 class="font-weight-bold">新增主題團購</h4>
        </div>
      </div>

      <b-overlay :show="showLoading">
        <theme-groupbuying-form
          ref="form"
          :form="form"
          :start-at="startAt"
          :end-at="endAt"
          :img-file.sync="imgFile"
          :validation-errors="validationErrors"
          form-mode="create"
        />
      

      <div class="d-flex justify-content-center">
        <b-button
          class="mr-3"
          variant="outline-danger"
          @click="$router.push({ name: 'ThemeGroupbuyingList' })"
          >返回</b-button
        >
        <b-button variant="success" @click="handleSubmit">儲存</b-button>
      </div>

      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import themeGroupbuyingApi from "@/apis/themeGroupbuying";
import ThemeGroupbuyingForm from "./ThemeGroupbuyingForm";
import imageMixin from "@/mixins/uploadImage";

export default {
  mixins: [imageMixin],
  components: { ThemeGroupbuyingForm },

  data() {
    return {
      imgFile: null,
      startAt: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      endAt: {
        date: new Date(),
        time: {
          HH: "23",
          mm: "59",
        },
      },
      form: {
        type: 80,
        title: "",
        description: null,
        pic_url: "",
        tag_title: null,
        tag_color: "#DE006F",
        tag_font_color: "#FFFFFF",
        footer_contents: [
          {
            label: "",
            uri: "",
          },
        ],
        order: 1,
        start_at: null,
        end_at: null,
      },
      validationErrors: null,
      showLoading: false,
    };
  },

  methods: {
    async handleSubmit() {
      const result = await this.$refs.form.validate();
      if (!result) return;

      this.showLoading = true;

      if (this.imgFile) {
        const imgUrl = await this.uploadByBase64(this.imgFile);
        if (!imgUrl) {
          return;
        }
        this.form.pic_url = imgUrl;
      }

      //Upload footer_contnets image
      for (let i in this.form.footer_contents) {
        if (
          this.form.footer_contents[i].img_url != undefined &&
          this.form.footer_contents[i].img_url.startsWith("data:")
        ) {
          const imgUrl = await this.uploadByBase64(
            this.form.footer_contents[i].img_url
          );
          if (imgUrl) {
            this.form.footer_contents[i].img_url = imgUrl;
          }
        }
      }

      try {
        let response = await themeGroupbuyingApi.storeThemeGroupbuyings(
          this.form
        );

        if (response.status && response.status === 200) {
          this.$swal.fire({
            title: "新增成功",
            type: "success",
          });

          this.$router.push({ name: "ThemeGroupbuyingList" });
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: "新增失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
